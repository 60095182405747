import { useEffect, useState, Fragment } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useQuery, useMutation, gql } from '@apollo/client'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import CampaignIcon from '@mui/icons-material/Campaign'
import parse from 'html-react-parser'
import { IonContent, IonPage } from '@ionic/react'
import { blue, orange, grey } from '@mui/material/colors'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import { TitleHeader } from '../components/Header'
import { getSessionUser } from '../auth'
import { LOGIN_PATH, NOTIFICATION_MESSAGE_PATH } from '../path'
import ProgressBar from '../components/ProgressBar'

const GET_NOTIFICATIONS = gql`
query get_notifications($limit:Int!, $cursor:ID) {
	notifications(
		sort:{
			dir:desc
		}
		limit: $limit
		cursor: $cursor
	) {
		count
		cursor
		data {
			id
			read_date
			create_date
			data {
				id
				title
				type
				message
				link_url
			}
		}
	}
}`

const MARK_AS_READ = gql`
mutation mark_as_read($id:ID!) {
	notification_mark_as_read(ids:[$id]) {
		id
		count
		data {
			id
			read_date
		}
	}
}`

const MyListItem = styled(ListItem).attrs(({ read }:any) => ({ read }))`
	background-color: ${(props:any) => !props.read ? blue[50] : 'unset'};
	cursor: pointer;
	&:hover {
		background-color: ${(props:any) => !props.read ? grey[200] : grey[100]};
	}
`

const Notification: React.FC = () => {
	const head = <Helmet>
		<title>Wand - Notifications</title>
	</Helmet>
	
	const notifications = useQuery(GET_NOTIFICATIONS, {
		variables: {
			limit:100
		}
	})
	const [markAsRead] = useMutation(MARK_AS_READ)

	const history = useHistory()
	const location = useLocation<any>()

	useEffect(() => {
		if (!notifications.loading && notifications.called)
			notifications.refetch()
	},[location, notifications])

	// Manages redirection to login page if user is not authenticated
	const [redirect, setRedirect] = useState<boolean | null>(null)
	useEffect(() => getSessionUser().then((resp: any) => setRedirect(resp && resp[0] ? true : false)), [])
	if (redirect === null)
		return null

	const Icon = ({ ...props }:any) => {
		const { type } = props

		const style = {
			bgcolor: type == 'tip' ? blue[600] : orange[500]
		}

		const Icn = type == 'tip' ? <TipsAndUpdatesIcon/> : <CampaignIcon/>

		return (<Avatar sx={style}>
			{Icn}
		</Avatar>)
	}

	const openNotification = (notification:any) => {
		if (!notification.read_date)
			markAsRead({ variables: { id:notification.id } })
		if (notification?.data?.link_url)
			window.open(notification?.data?.link_url, '_blank')
		else if (notification?.data?.message || notification?.data?.body) 
			history.push(`${NOTIFICATION_MESSAGE_PATH}/${notification.id}`, { title:notification?.data?.title })
	}

	const preview_message = msg => {
		const [,invite_id, invitee] = `${msg}`.match(/^invite_id_([0-9]+?)_invitee_(.+?)$/)||[]
		if (invite_id && invitee)
			return `New invitation from ${invitee}`
		else
			return parse(msg)
	}

	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<div>
			<IonPage>
				{notifications.loading && <ProgressBar/>}
				{head}
				<TitleHeader title="Notifications" onBack={() => history.push('/')} />
				<IonContent fullscreen>{!(notifications.data?.notifications?.data||[]).length ?
					<div style={{ display:'flex', justifyContent:'center', marginTop:'50px' }}>
						<Typography color="text.secondary">No notifications yet</Typography>
					</div> : 
					<List sx={{ width: '100%', bgcolor: 'background.paper', marginTop:'-8px' }}>{notifications.data.notifications.data.map((n:any, idx:number) => (
						<Fragment key={n.id}>
							<MyListItem alignItems="flex-start" key={n.id} read={n.read_date} onClick={() => openNotification(n)}>
								<ListItemAvatar>
									<Icon type={n.data?.type}/>
								</ListItemAvatar>
								<Box>
									<Typography variant="h6" sx={{ fontSize:'18px' }}>{n.data?.title}</Typography>
									<Typography
										sx={{ 
											marginTop: '5px',
											marginBottom: '10px',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											display: '-webkit-box',
											'-webkit-line-clamp': '2', /* number of lines to show */
											'-webkit-box-orient': 'vertical'
										}}
										component="span"
										variant="body2"
										// color="text.primary"
									>{preview_message(n.data?.message||'')}</Typography>
									<Typography
										sx={{ marginTop: '15px', opacity:'0.8', fontStyle:'italic' }}
										component="span"
										variant="caption"
										// color="text.primary"
									>{new Date(n.create_date).toLocaleString()}</Typography>
								</Box>
							</MyListItem>
							{idx < ((notifications.data?.notifications?.data||[]).length-1) && <Divider variant="inset" component="li" />}
						</Fragment>))}
					</List>}
				</IonContent>
			</IonPage>
		</div>
	)
}

export default Notification
