import { Fragment, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, gql } from '@apollo/client'
import Card from '@mui/material/Card'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import styled from 'styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { styled as mdStyled } from '@mui/material/styles'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import ArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { DateTime } from 'luxon'
import { SETTINGS_UPGRADE_PLAN_PATH } from '../path'
import { useLogFeatureInfo } from '../utils/session'
import DateInfo from './DateInfo'
import './DataCard.css'
import { useUser } from '../data/user'
import { is_plan_on } from '../utils/global'

const ONE_MIN_IN_MS = 1000*60
const GOOGLE_TAG_MANAGER_EVENT = 'ad_ready'

const FORECAST_24HOURS_STEP_VALUES = {
	ONE_HOUR: 1,
	TWO_HOURS: 2,
	THREE_HOURS: 3,
	FOUR_HOURS: 4,
	SIX_HOURS: 6,
	TWELVE_HOURS: 12
}

const SET_DEFAULT_TOWER = gql`
mutation set_default_tower($id: ID!) {
	user_update(default_tower_id: $id) {
		data{
			id
			default_tower_id
		}
	}
}`

const MyCard = styled(Card)`
	align-self: center;
	position: relative;
	margin-top: -15px;
	height: var(--v-height); // 1070px; when all extra fields are in
	border-radius: 12px !important;

	& .upgrade {
		display: flex;
		justify-content: start;

		& .upgrade-btn {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 20px;
			background-color: black;
			color: white;
			border-radius: 30px;
			width: 112px;
			font-size: 10px;
			font-weight: 500;

			& .upgrade-text {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: -5px;
			}

			& svg {
				font-size:16px;
			}
		}
	}

	& .forecast-container {
		position: relative;
		margin-bottom: 10px; 

		& .forecast-slider {
			position: relative;
			display: grid;
			width: 100%;
			grid-template-columns: 23px 1fr 23px;
			grid-template-rows: 73px;
			grid-template-areas: 'btnleft slider btnright';
		}

		& .forecast-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #1976d2;

			&:hover {
				background-color: #1c84ec;
			}

			&:active {
				background-color: #1976d2;
			}

			& svg {
				color: white;
			}
		}

		& .forecast-left-btn {
			grid-area: btnleft;
			border-radius: 7px 0px 0px 7px;
		}

		& .forecast-right-btn {
			grid-area: btnright;
			border-radius: 0px 7px 7px 0px;
		}

		& .forecast {
			grid-area: slider;
			display: flex;
			width: 100%;
			padding-top: 11px;
			overflow-x: scroll;
			position: absolute;
			border-top: 1px solid #00000012;
			border-bottom: 1px solid #00000012;
			box-sizing: border-box;

			& .forecast-item {
				display: flex;
				flex-direction: column;

				& .forecast-item-header {
					width: 60px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-weight: 500;
				}

				& .forecast-item-body {
					width: 60px;
					height: 40px;
					display: flex;
					justify-content: center;
					align-items: center;

					& .unsafe-icon {
						color: #ef5350;
					}

					& .safe-icon {
						color: #1876D1;
					}

					& .unavailable {
						cursor: default;
					}

					& .upgrade-icon {
						cursor: pointer;
						color: white;
						border-radius: 30px;
						background-color: black;
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		& .forecast::-webkit-scrollbar {
			display: none;
		}
	}

	@media (max-width: 450px) {
		transform: translate(-20px, 0px);
	}

	@media (max-width: 400px) {
		transform: translate(-35px, 0px);
	}
`

const RowItem = styled.div`
	display: grid;
	grid-template-columns: 7fr 4fr;
	align-items: center;
	width: 100%;
	min-width: 340px;
	height: 36px;

	& .label {
		display: flex;

		& .text {
			text-align: left;
			width: 183px;
			white-space: nowrap;
			overflow: hidden;
		}

		& .question {
			width: 20px;
			height: 20px;
			background-color: #e1e4e6;
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: -2px;
			left: 6px;
			cursor: pointer;

			& svg {
				font-size: 14px;
				opacity: 0.4;
			}

			&.hazard {
				left: -20px;
			}
		}
	}

	@media (max-width: 500px) {
		grid-template-columns: 10fr 4fr;
		min-width: 300px;
	}

	@media (max-width: 400px) {
		min-width: 270px;
	}
`

const Table = styled.div`
	margin: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@keyframes show_ad {
		from {
			top: 250px;
		}
		to {
			top: 0;
		}
	}

	& .hazard-data {
		border: 1px solid #0000004d;
		border-radius: 7px;
		padding: 4px 15px 6px 15px;
		margin-bottom: 12px;
		position: relative;
		overflow: hidden;

		& legend {
			margin-left: -2px;
			font-weight: 600;
			font-size: 14px;
			opacity: 0.7;
		}
	}
`

const HazardStatus = mdStyled(({ ...props }:any) => {
	return <Box {...props}>{props.children}</Box>
})(({ theme, danger, neutral }) => ({
	fontSize: '16px',
	color: neutral == 'true' ? 'black' : danger == 'true' ? theme.palette.error.light : theme.palette.primary.main,
	fontWeight: neutral == 'true' ? '400' : 'bold'
}))

const Header = styled.div`
	display: grid;
	padding: 15px 26px 20px 26px;
	grid-template-rows: 32px 24px 50px;
	grid-template-columns: 1fr 100px;
	grid-template-areas: 
		'title    icon'
		'time     time'
		'warning  warning';

	& .h-title {
		grid-area: title;
	}

	& .h-icon {
		grid-area: icon;

	}

	& .h-time {
		grid-area: time;
		display: flex;
		justify-content: flex-start;
		font-style: italic;
	}

	& .big-warning {
		grid-area: warning;
		margin-top: 5px;
		margin-bottom: -5px;
		border: 1px solid var(--ion-color-danger);
		border-radius: 6px;
		background-color: #eb445a12;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		& .h-warning {
			max-width: 300px;
			font-style: italic;
			font-size: 12px;
			color: var(--ion-color-danger);

			&.offline {
				font-size: 16px;
			}
		}
	}
`

const scrollForecast = (direction:string, node:any) => {
	const step = direction == 'right' ? 250 : -250
	return () => {
		if (node && node.current && node.current.scrollBy)
			node.current.scrollBy({ left: step, behavior: 'smooth' })
	}
}

const Row = (input:any) => {
	const { name, value, unit, hazard, onHelp, showHelp, hazardGroup } = input

	return (
	<RowItem>
		<div className="label">	
			<div className="text">{name}</div>	
			<div style={{ position:'relative' }}>	
				{showHelp && <div className={hazardGroup ? "question hazard" : "question"} onClick={onHelp}><QuestionMarkIcon/></div>}
			</div>
		</div>
		{hazard 
			? (<Box sx={{ display:'flex', alignItems:'center', gap:'6px' }}>
				<HazardStatus danger={value=='Present' ? "true" : "false"} neutral={value=='--' ? "true" : "false"}>{value}</HazardStatus>
			</Box>)
			: <div>{`${value||'--'}${unit ? ` ${unit}` : ''}`}</div>}
	</RowItem>)
}

const ForecastRow = (input:any) => {
	const node = useRef()
	const history = useHistory()

	const scrollLeft = scrollForecast('left', node)
	const scrollRight = scrollForecast('right', node)

	const { name, value, onHelp, showHelp, towerTz, upgrade } = input
	const forecastExists = value && value.length
	const now:any = new Date()
	let dt:any = DateTime.fromISO(new Date().toISOString())
	if (towerTz)
		dt = dt.setZone(towerTz)

	const nowHour = dt.get('hour')

	const addHour = (hour:number, hourAmount:number) => {
		const nextHour = hour + hourAmount
		const val = nextHour >= 24 ? nextHour - 24 : nextHour
		return !val ? '12AM' : val < 12 ? `${val}AM` : val == 12 ? '12PM' : `${val-12}PM`
	}

	const start_date = ((value||[])[0]||{}).start_date
	const end_date = ((value||[]).slice(-1)[0]||{}).end_date
	const relevantForecasts = forecastExists && start_date && end_date && start_date < end_date && new Date(start_date) <= now && new Date(end_date) > now

	const go_to_upgrade = () => {
		history.push(SETTINGS_UPGRADE_PLAN_PATH)
	}

	const data = !relevantForecasts ? [] : (value||[]).reduce((acc:any, x:any, i:number) => {
		const step = FORECAST_24HOURS_STEP_VALUES[process.env.REACT_APP_FORECAST_24HOURS_STEP as keyof typeof FORECAST_24HOURS_STEP_VALUES] || 2
		const idx = step*i
		const startHour = addHour(nowHour, idx)
		const endHour = addHour(nowHour, idx+step)
		let unsafe = null
		if (x.start_date && x.end_date) {
			const start = new Date(x.start_date)
			const end = new Date(x.end_date)
			if (!acc.valid)
				acc.valid = start <= now && now < end
			unsafe = x.unsafe
		}
		if (acc.valid) {
			const item = {
				startHour,
				endHour,
				unsafe,
				el: (<div title="Unavailable" className="unavailable">--</div>)
			}
			if (unsafe === null)
				item.el = (<div title="Unavailable" className="unavailable">--</div>)
			else if (unsafe)
				item.el = (<div title="Present"><ErrorIcon className="unsafe-icon"/></div>)
			else 
				item.el = (<div title="Absent"><CheckCircleOutlineIcon className="safe-icon"/></div>)

			if (upgrade && acc.data.length >= 2)
				item.el = (<div title="Upgrade"><UpgradeIcon className="upgrade-icon" onClick={go_to_upgrade}/></div>)
			acc.data.push(item)
		}
		return acc
	}, { valid:false, data:[] }).data

	return (<div className="forecast-container">
		<RowItem>
			<div className="label">	
				<div className="text">{upgrade ? name.replace('24', '2') : name}</div>	
				<div style={{ position:'relative' }}>	
					{showHelp && <div className="question hazard" onClick={onHelp}><QuestionMarkIcon/></div>}
				</div>
			</div>{!upgrade ? null :
			<div className="upgrade">
				<div className="upgrade-btn" onClick={go_to_upgrade}>
					<div className="upgrade-text">
						<UpgradeIcon/>
						Get 24hrs forecast
					</div>
				</div>
			</div>}
		</RowItem>{!data.length ?
			<div>Not available</div> :
			<div className="forecast-slider">
				<button 
					onMouseDown={scrollLeft} 
					onTouchStart={scrollLeft} 
					className="forecast-btn forecast-left-btn"><ArrowLeft/></button>
				<div className="forecast" ref={node}>{data.map((d:any, i:any) => 
					<div className="forecast-item" key={i}>
						<div className="forecast-item-header">{d.endHour}</div>
						<div className="forecast-item-body">{d.el}</div>
					</div>)}
				</div>
				<button 
					onMouseDown={scrollRight} 
					onTouchStart={scrollRight} 
					className="forecast-btn forecast-right-btn"><ArrowRight/></button>
			</div>}
	</div>)
}

/**
 * Formula from https://github.com/GoannaAg/GoApp-Data/blob/868cab134122a7d3cdbddae7b423d88e484d2ad6/DataServices/GWeather.DataBase/Stored%20Procedures/Procs2/GWUpdateGWWeatherNowByLocationID.sql#L156
 * 
 * @param	{Number} humidity
 * @param	{Number} air_temp
 * 
 * @return	{Number} dew_point
 */
const compute_dew_point = (humidity:any, air_temp:any) => {
	humidity = humidity || 0
	air_temp = air_temp || 0
	const top =  243.12* ( Math.log( humidity/100) +  (17.62 * air_temp)/ (243.12+air_temp))
	const bottom  =  17.62 -  ( Math.log( humidity/100) +  (17.62 * air_temp)/ (243.12+air_temp))
	
	return bottom ? (top / bottom) : (top/0.1)
}

/**
 * Caclulates the "Grassland Fire Danger Index" (wind speed at 10 meter) or the 
 * "Grain Harvest Code of Practice" (wind speed at 2 meter).
 *
 * Definition: https://mesonet.com.au/about/observations
 * Formula: https://calculator.academy/grassland-fire-danger-index-calculator/
 * 
 * @param	{Number}	drought_factor				
 * @param	{Number}	temp				
 * @param	{Number}	humidity				
 * @param	{Number}	wind_speed		
 * 		
 * @return	{Number}	gfdi
 */
// const compute_gfdi = (drought_factor:number, temp:number, humidity:number, wind_speed:number) => {
// 	return 0.1 * drought_factor * (33 - (0.037 * temp)) * (1 - (0.01 * humidity)) * (wind_speed + 10)
// }

const parseReading = (reading:any, is_out_of_sync:boolean, is_offline:boolean) => {
	if (!reading)
		return []

	const {
		deltat_1,
		temp_1,
		tempdiff_h10_h1_avg,
		ws_2,
		ws_10,
		wd_10_label,
		wd_cv2_label,
		windgust_2,
		rain_acc,
		rain_prev_24h,
		solardown_10min,
		hazard_status,
		nowcast,
		next_24_hours,
		humidity
	} = reading

	const hazard_data_unavailable = hazard_status === null || hazard_status === undefined 
	const hazardStatus = hazard_data_unavailable ? '--' : hazard_status
	const hazardStatusCanon = `${hazardStatus}`.toLowerCase().trim()
	const nowCast = hazardStatusCanon == 'present' 
		? 'Present' 
		: nowcast === null || nowcast === undefined || nowcast === '' 
			? '--' 
			: nowcast <= 0.5 ? 'Absent' : 'Present' 

	const next24Hours = (next_24_hours||[]).map(d => ({ ...d }))
	if (next24Hours && next24Hours.length >= 2 && (nowCast == 'Absent' || nowCast == 'Present')) {
		const end_date:any = new Date(next24Hours[1]?.end_date)
		if (end_date && !isNaN(end_date)) {
			const ellapsedHours = (end_date.getTime() - Date.now())/1000/60/60
			if (ellapsedHours > 0 && ellapsedHours < 2.3) {
				const mostReliableUnsafe = nowCast == 'Present'
				next24Hours[0].unsafe = mostReliableUnsafe
				next24Hours[1].unsafe = mostReliableUnsafe
			}
		}
	}
	if (hazard_data_unavailable)
		for (let i=0;i<next24Hours.length;i++)
			next24Hours[i].unsafe = null

	return [{
		key: 'hazard_status',
		label: 'Hazardous Inversion',
		description: `
		When determining if weather conditions are suitable for spraying applicators <b>MUST</b> adhere to all label 
		requirements, operate using best practice and consider site specific conditions and the proximity 
		of sensitive vegetation (crops, native plants) and water ways. If a <b>Hazardous Inversion</b> is Present, 
		then no spraying is to be undertaken.
		</br>
		</br>
		If a Hazardous Inversion is <b>Absent</b>, however an <b>Inversion (Vert temp diff)</b> is present, represented by 
		a positive (+ ve) number, then conditions are suitable for spraying but extra caution may be needed. 
		Consider the direction of wind speed (2m) and max wind gust in relation to nearby sensitive areas 
		and avoid spraying under still conditions.
		</br>
		</br>
		Labels meaning:
		</br>
		<b>Present</b>: Hazardous inversion is present
		</br>
		<b>Absent</b>: No hazardous inversion`,
		value: is_offline || is_out_of_sync ? '--' : hazardStatus,
		hazard: true
	}, {
		key: 'nowcast',
		label: 'NowCast',
		description: `
		Prediction of the Absence or Presence of a Hazardous Inversion occurring in the next 2 hours.
		</br>
		</br>
		Labels meaning:
		</br>
		<b>Present</b>: Hazardous inversion is present
		</br>
		<b>Absent</b>: No hazardous inversion`,
		value: is_offline || hazard_data_unavailable || is_out_of_sync ? '--' : nowCast,
		hazard: true
	}, {
		key: 'next_24_hours',
		label: '24 hours forecast',
		description: `Prediction of the Absence or Presence of a Hazardous Inversion occurring in the next 24 hours.`,
		value: is_offline ? '--' : next24Hours
	}, {
		key: 'tempdiff_h10_h1_avg',
		label: 'Inversion (Vert Temp Diff)',
		description: `An inversion occurs when temperature increases with height. It is measured as the Vertical Temperature 
		Difference (degrees C) between 1.25m and 10m above the surface. A positive (+ve) number represents the presence 
		of a surface temperature inversion.`,
		value: is_offline || tempdiff_h10_h1_avg === null || tempdiff_h10_h1_avg === undefined ? '--' : formatNbr(tempdiff_h10_h1_avg, 1, '°C')
	}, {
		key: 'ws_2',
		label: 'Wind Speed (2m)',
		description: `
		The 2m wind should to be recorded at the site of application as per Australian Pesticide and 
		Veterinary Medicines Authority (APVMA) advice. The wind reported is the average of the last 10 minutes. 
		The average wind speed at the application site during the period of application must not exceed the 
		maximum stated on the product label.
		</br>
		</br>
		The wind direction is true north and is the direction from which the wind is blowing.
		`,
		value: is_offline || ws_2 === null || ws_2 === undefined ? '--' : `${formatNbr(ws_2*3.6, 0, 'km/h')}${wd_cv2_label ? ` ${wd_cv2_label}` : ''}`
	}, {
		key: 'windgust_2',
		label: 'Max Wind Gust (2m)',
		description: `The maximum wind speed (gust) recorded during the last 10 minutes.`,
		value: is_offline || windgust_2 === null || windgust_2 === undefined ? '--' : formatNbr(windgust_2*3.6, 0, 'km/h')
	}, {
		key: 'ws_10',
		label: 'Wind Speed (10m)',
		description: `
		10 m winds are the winds observed and forecast by the Bureau of Meteorology (BoM) and news services. It is commonly referred to as the synoptic wind.
		The wind reported is the average of the last 10 minutes.
		</br>
		</br>
		The wind direction is true north and is the direction from which the wind is blowing.
		`,
		value: is_offline || ws_10 === null || ws_10 === undefined ? '--' : `${formatNbr(ws_10*3.6, 0, 'km/h')}${wd_10_label ? ` ${wd_10_label}` : ''}`
	}, {
		key: 'deltat_1',
		label: 'Delta T',
		description: `
		The difference between the wet and dry bulb temperature. It combines the evaporation effects of 
		temperature and relative humidity.
		</br>
		A common spray guideline is to spray when Delta T is between 2 and 8, with caution below 2 or above 10. 
		Between 10 and 12 conditions are marginal for COARSE or greater spray quality and unsuitable for Medium 
		or finer spray quality.
		`,
		value: is_offline || deltat_1 === null || deltat_1 === undefined ? '--' : formatNbr(deltat_1, 1, '°C')
	}, {
		key: 'temp_1',
		label: 'Temperature',
		description: `Standard air temperature is measured at 1.25m above the surface in a radiation screen.`,
		value: is_offline || temp_1 === null || temp_1 === undefined ? '--' : formatNbr(temp_1, 0, '°C')
	}, {
		key: 'solardown_10min',
		label: 'Solar Radiation',
		description: `
		Solar radiation (or light intensity) drives plant photosynthesis, and growth and reproductive 
		structure development. Solar radiation at Earth’s surface is typically defined as total radiation 
		across a wavelength rage of 280 to 4000 nm (shortwave radiation) and is expressed in Watts per square 
		meter which can be converted to MJ/m/unit of time.
		`,
		value: is_offline || solardown_10min === null || solardown_10min === undefined ? '--' : formatNbr(solardown_10min, 0, 'W/m2')
	}, {
		key: 'rain_acc',
		label: 'Rainfall from 9 am',
		paid: true,
		description: `The amount of rainfall received since 9:00 am.`,
		value: is_offline || rain_acc === null || rain_acc === undefined ? '--' : formatNbr(rain_acc, 0, 'mm')
	}, {
		key: 'rain_prev_24h',
		label: 'Rainfall 24 hrs to 9 am',
		paid: true,
		description: `The amount of rainfall received in the last 24 hour period until 9:00 am today.`,
		value: is_offline || rain_prev_24h === null || rain_prev_24h === undefined ? '--' : formatNbr(rain_prev_24h, 0, 'mm')
	}, {
		key: 'humidity',
		label: 'Relative Humidity',
		paid: true,
		description: `The average relative humidity for the past 10 minutes in percent.`,
		value: is_offline || humidity === null || humidity === undefined ? '--' : formatNbr(humidity, 0, '%')
	}, {
		key: 'dew_point',
		label: 'Dew Point',
		paid: true,
		description: `The dew point is the temperature at which air becomes saturated with moisture, causing water vapor to condense into dew. It's a key indicator of humidity levels.`,
		value: is_offline || humidity === null || humidity === undefined || temp_1 === null || temp_1 === undefined ? '--' : formatNbr(compute_dew_point(humidity,temp_1), 1, '°C')
	}, 
	// {
	// 	key: 'evapotranspiration',
	// 	label: 'Evapotranspiration',
	// 	description: `Evapotranspiration is the process by which water is transferred from the land to the atmosphere by evaporation from soil and other surfaces and by transpiration from plants.`,
	// 	value: '--'
	// }, {
	// 	key: 'thermal_time',
	// 	label: 'Thermal Time',
	// 	description: `Thermal Time is a measure of accumulated heat used to predict plant growth stages, calculated using temperature thresholds over a period.`,
	// 	value: '--'
	// }, {
	// 	key: 'vernalisation_time',
	// 	label: 'Vernalisation Time',
	// 	description: `Vernalization time refers to the required period of cold exposure for certain plants to initiate or accelerate flowering. It's essential for the development of some crops and flowering species.`,
	// 	value: '--'
	// }, {
	// 	key: 'adjusted_thermal_time',
	// 	label: 'Adjusted Thermal Time',
	// 	description: `Definition for this field coming soon...`,
	// 	value: '--'
	// }, {
	// 	key: 'grain_harvest_code_of_practice',
	// 	label: 'Grain Harvest Code of Practice',
	// 	description: `Definition for this field coming soon...`,
	// 	value: '--'
	// }, {
	// 	key: 'grass_fire_danger_index',
	// 	label: 'Grass Fire Danger Index',
	// 	description: `Definition for this field coming soon...`,
	// 	value: '--'
	// }
	]
}

const formatNbr = (nbr:any, decimals?:number, unit?:string) => {
	const n = nbr*1
	if (isNaN(n))
		return '--'

	return `${!decimals || decimals <= 0 ? Math.round(n) : n.toFixed(decimals)}${unit ? ` ${unit}` : ''}`
}

const convertDateToLocal = (date_utc?:string, tz?:string) => {
	let dt = DateTime.fromISO(date_utc||new Date().toISOString())
	if (tz)
		dt = dt.setZone(tz)

	return dt.toFormat('dd/MM/yyyy HH:mm')
}

const convertDateToNext2hoursLocalTime = (date_utc?:string, tz?:string) => {
	let dt = DateTime.fromISO(date_utc||new Date().toISOString()).plus({ hours: 2 })
	if (tz)
		dt = dt.setZone(tz)

	return dt.toFormat('HH:mm')
}

const get_ad_info_setter = (ad_info:any, set_ad_info:any, on_ad_loaded:any) => () => {
	const _set_ad_info = ({ ...props }) => {
		if (on_ad_loaded && props.image)
			on_ad_loaded()
		set_ad_info(props)
	}
	const ad_el = document.getElementById('ad-image-data')
	if (ad_el) {
		const image = ad_el.getAttribute('data-image')
		const destination = ad_el.getAttribute('data-destination')
		if (ad_info?.image != image || ad_info?.destination != destination)
			_set_ad_info({ image, destination })
	} 
	// else {
	// 	_set_ad_info({ 
	// 		image:'https://dev-7gh5cmcw.wand.com.au/assets/image/wand-advert-1.jpg', 
	// 		destination:'https://bit.ly/qmdb-web' // https://www.goannaag.com/qmdb
	// 	})
	// }
}

const AdContainer = styled.div`
	border-radius: 7px;
	overflow: hidden;
	width: calc(100% - 4px);
	margin-bottom: 20px;

	& img {
		float: left;
		width: 100%;
		object-fit: cover;
	}
`

const Ad = ({ ...props }) => {
	const { image, destination } = props
	const logFeat = useLogFeatureInfo()
	const user_op = useUser()

	const track_click = () => {
		try {
			const { id, email, first_name, last_name } = (user_op?.data?.users?.data||[])[0]||{}
			logFeat({ 
				title:'ad_clicked_now_page', 
				content: id ? { id, email, first_name, last_name } : {}
			})
		} catch(err) {
			console.warn(err)
		}
	}

	return (!image ? null : <AdContainer onClick={track_click}>
		<a href={destination} target="_blank" rel="noreferrer">
			<img id="ad-banner" src={image} alt="wand ad"/>
		</a>
	</AdContainer>)
}

const CardFields = ({ ...props }) => {
	const { fields, mode, date_utc, towerTz, onHelp, on_ad_loaded, act_as_paid_user } = props
	const [hazard_status, nowcast, next_24_hours, ...rest] = fields||[]
	
	const plan_on = is_plan_on()

	const accessible_fields = !act_as_paid_user 
		? rest.filter((x:any) => !x.paid)
		: rest

	const [ad_info, set_ad_info] = useState<any>({})
	const ad_info_setter = get_ad_info_setter(ad_info, set_ad_info, on_ad_loaded)

	useEffect(() => {
		ad_info_setter()
		document.addEventListener(GOOGLE_TAG_MANAGER_EVENT, ad_info_setter)
		return () => {
			document.removeEventListener(GOOGLE_TAG_MANAGER_EVENT, ad_info_setter)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (!hazard_status || !nowcast || !next_24_hours ? null : 
	<CardContent sx={{ paddingTop:'5px', backgroundColor:'white' }}>
		<Table>{act_as_paid_user ? null :
			<Ad image={ad_info?.image} destination={ad_info?.destination}/>}
			<fieldset className="hazard-data">
				<Row 
					id={hazard_status.key} 
					name={hazard_status.label} 
					value={hazard_status.value} 
					hazard={hazard_status.hazard}  
					showHelp={mode == 'now'}
					hazardGroup={true}
					onHelp={() => onHelp(hazard_status.label, hazard_status.description)} />{plan_on ? null : 
				<Row 
					id={nowcast.key} 
					name={`${nowcast.label} (until ${convertDateToNext2hoursLocalTime(date_utc, towerTz)})`} 
					value={nowcast.value} 
					hazard={nowcast.hazard}  
					showHelp={mode == 'now'}
					hazardGroup={true}
					onHelp={() => onHelp(nowcast.label, nowcast.description)} />}
				<ForecastRow
					id={next_24_hours.key} 
					name={next_24_hours.label} 
					value={next_24_hours.value} 
					towerTz={towerTz}
					showHelp={mode == 'now'}
					upgrade={!act_as_paid_user}
					onHelp={() => onHelp(next_24_hours.label, next_24_hours.description)}/>
			</fieldset>{accessible_fields.map((p:any, key:number) => { return (
			<Row 
				id={p.key} 
				name={p.label == 'NowCast' ? `${p.label} (until ${convertDateToNext2hoursLocalTime(date_utc, towerTz)})` : p.label} 
				value={p.value} 
				hazard={p.hazard} 
				key={key} 
				showHelp={mode == 'now'}
				onHelp={() => onHelp(p.label, p.description)} />)})}
		</Table>
	</CardContent>)
}

const DataCard = ({ ...props }) => {
	const { 
		towerId, 
		towerName, 
		loading, 
		towerTz, 
		data, 
		defaultButton=true, 
		defaultTower=false, 
		onHelp, 
		mode, 
		onRefresh,
		dateSideGutter,
		classes,
		act_as_paid_user
	} = props

	const [card_height, set_card_height] = useState('810px')

	// Mark as 'out of sync' when the latest data is greater than 2 hours ago
	const current_time = new Date(data?.date_utc).getTime()
	let is_out_of_sync = true
	let is_offline = false
	if (!isNaN(current_time)) {
		const ONE_HOUR_IN_MIN = 60
		const time_diff_in_min = Math.abs(Date.now() - current_time)/ONE_MIN_IN_MS
		is_out_of_sync = time_diff_in_min > 2*ONE_HOUR_IN_MIN
		is_offline = time_diff_in_min > 24*ONE_HOUR_IN_MIN
	}

	const obj:any = document.querySelector('.gradient-bg')
	if (obj?.style?.display !== undefined)
		obj.style.display = is_offline ? 'none' : ''

	const timeLabel = mode == 'now'
		? `As of ${convertDateToLocal(data?.date_utc, towerTz)}`
		: `As of ${convertDateToLocal(data?.date_utc, towerTz)} (${towerTz})`

	const [setDefaultTower] = useMutation(SET_DEFAULT_TOWER)
	const logFeat = useLogFeatureInfo()

	useEffect(() => {
		if (mode == 'now')
			logFeat({ title:'last_reading', content:towerName })
		else
			logFeat({ title:'spray_log', content:towerName })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [towerName, mode])

	const setDefault = (val:boolean) => {
		setDefaultTower({ variables: { id: val ? towerId : '' } })
		// alert(`WORK IN PROGRESS: Task for setting tower ID ${towerId} to default ${val} documented in GitHub issue https://github.com/GoannaAg/inversion-tower-doc/issues/3`)
	}

	const on_ad_loaded = () => set_card_height('1015px')

	return (
		<Fragment>
			{!data && loading && 
			<Box sx={{ marginTop:'50px' }} className="progressive-display">
				<CircularProgress/>
			</Box>}
			{(data || !loading) && 
			<div style={{ width:'100%', display:'flex', flexDirection:'column', marginTop:'10px', '--v-height':card_height }} className={(classes||[]).join(' ')}>{mode == 'now' ?
				<DateInfo 
					tz={towerTz} 
					is_offline={is_offline}
					loading={loading} 
					sideGutter={dateSideGutter}
					lastReadingTime={data?.date_utc} 
					onRefresh={onRefresh}
					classes="datacard" /> : null}
				<MyCard className="progressive-display">
					<Header>
						<Typography variant="h6" sx={{ fontWeight:'bold' }} className="h-title">{towerName}</Typography>
						{defaultButton && <FormControlLabel
							value="start"
							control={<Switch 
								color="primary" 
								defaultChecked={defaultTower}
								onChange={(e:any) => setDefault(e.target.checked)} />}
							label={<Typography variant="body2" color="primary">Default</Typography>}
							labelPlacement="start"
							className="h-icon"
						/>}<Typography variant="body2" color="text.secondary" className="h-time">{is_offline ? '' : timeLabel}</Typography>
						<div className="big-warning">
							<Typography variant="body2" className={is_offline ? 'h-warning offline' : 'h-warning'}>
								{is_offline ? 'Tower offline' : 'All label requirements must be met including wind speed during periods of application.'}
							</Typography>
						</div>
					</Header>
					<Divider/>
					<CardFields 
						fields={parseReading(data, is_out_of_sync, is_offline)}
						act_as_paid_user={act_as_paid_user}
						mode={mode}
						date_utc={data?.date_utc}
						towerTz={towerTz}
						onHelp={onHelp}
						on_ad_loaded={on_ad_loaded}/>
				</MyCard>
			</div>}
		</Fragment>
	)
}

export default DataCard
