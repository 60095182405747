import { useEffect, useState, useRef, useMemo, Fragment } from 'react'
import { IonContent, IonPage, useIonToast, IonModal } from '@ionic/react'
import { useQuery, useMutation, gql } from '@apollo/client'
// import { validateEmail } from 'puffy-core/validate'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import BusinessIcon from '@mui/icons-material/Business'
import NumbersIcon from '@mui/icons-material/Numbers'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import { getSessionUser } from '../auth'
import { LOGIN_PATH, SETTINGS_PATH } from '../path'
import Toast from '../utils/Toast'
import SaveCancelButton from '../components/SaveCancelButton'
import { TitleHeader } from '../components/Header'
import { GET_USER_GQL, UPDATE_FULLNAME } from '../data/user'
import './Profile.css'

const UPDATE_PHONE = gql`
mutation update_phone($code:Int!, $number:Float!) {
	user_update(phone:{ code:$code number:$number }) {
		message
		data {
			id
			phone {
				code
				number
			}
		}
	}
}`

const UPDATE_METADATA = gql`
mutation update_metadata($metadata: String!) {
	user_update(metadata:$metadata){
		message
		data {
			id
			metadata
		}
	}
}`

const getExpectedModalBreakpoint = (h?:number) => {
	if (!h)
		return 0
	else if (h < 450)
		return 0.9
	else if (h < 600)
		return 0.8
	else 
		return 0.6
}

const _void = (e:any) => e

const concatPhoneDetails = (phone:any) => {
	const { code, number } = phone||{}
	const c = code === 0 ? `+0` : code ? `+${code}` : ''
	const n = number === 0 ? `0` : (number||'')
	return [c,n].filter(x => x).join(' ')
}

const parseMeta = (user:any) => {
	if (!user || !user.metadata)
		return {}
	
	let meta
	try {
		meta = JSON.parse(user.metadata) || {}
	} catch (err) {
		meta = {}
		_void(err)
	}

	return meta
}

const Field = ({ fields, onEdit, icon, disabled }:{ fields:{label:string, value:string}[], onEdit?:Function, icon?:any, disabled?:boolean }) => {
	const onClick = () => {
		if (onEdit)
			onEdit()
	}

	return (
		<div className="field-item">
			{icon && <Box className="profile-field-icon">
				{icon}
			</Box>}
			<Box className="profile-field-label">
			{fields.map((field:any, key:any) => {
				return (<Fragment key={key}>
					<Typography 
						variant="body1" 
						color={disabled ? 'text.disabled' : ''}
						sx={{ fontWeight:500, marginBottom:'3px', marginTop: key > 0 ? '6px' : '0px' }} 
						className="profile-field-label">
						{field.label}
					</Typography>
					<Typography 
						variant="body2" 
						color={disabled ? 'text.disabled' : ''}
						sx={{ marginBottom:'0px' }}>
						{field.value||'--'}
					</Typography>
				</Fragment>)
			})}
			</Box>
			{!disabled && <Box className="profile-field-edit">
				<Button variant="text" onClick={onClick} sx={{ marginTop:'-6px' }}>Edit</Button>
			</Box>}
		</div>
	)
}

const Form = ({ title, description, onCancel, onSave, children, saving }: { title?:any, description?:any, onCancel:Function, onSave:Function, children?:any, saving?:boolean }) => {
	return (
		<Box sx={{ padding:'26px', width:'100%' }}>
			<Box className="field-form">
				<Box sx={{ paddingBottom:description ? '0px' : '30px', display:'flex', marginTop:'-15px' }}>
					<Typography variant="h6" sx={{ width:'50%' }}>{title||''}</Typography>
					<Box sx={{ width:'50%', display:'flex', justifyContent:'flex-end' }}>
						<IconButton aria-label="close" sx={{ marginRight:'-15px' }} onClick={() => onCancel()}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
				{description && <Typography variant="body2" sx={{ marginBottom:'12px' }}>{description}</Typography>}
				{children}
			</Box>
			<SaveCancelButton onCancel={onCancel} saving={saving} onSave={onSave} sx={{ paddingTop:'30px' }} />
		</Box>
	)
}

const FormFullname = ({ firstname, lastname, onCancel, onSave, onError }: { firstname?:string, lastname?:string, onCancel:Function, onSave:Function, onError:Function }) => {
	const [updateFullname, fullnameOp] = useMutation(UPDATE_FULLNAME)
	const [_firstname, setFirstname] = useState(firstname)
	const [_lastname, setLastname] = useState(lastname)

	useEffect(() => {
		if (!fullnameOp.loading && fullnameOp.called) {
			if (!fullnameOp.error)
				onSave({ firstname:_firstname, lastname:_lastname })
			else {
				console.error(fullnameOp.error)
				onError()
			}
		}
	}, [fullnameOp, _firstname, _lastname, onSave, onError])

	const _onSave = () => {
		updateFullname({
			variables: {
				first_name: _firstname||'',
				last_name: _lastname||''
			}
		})
	}

	return (
		<Form 
			title="Edit full name" 
			onCancel={onCancel} 
			onSave={_onSave} 
			saving={fullnameOp.loading}>
			<TextField
				label="First name"
				value={_firstname}
				onChange={(e:any) => setFirstname(e.target.value)}
				placeholder="Enter your first name"
			/>
			<TextField
				label="Last name"
				value={_lastname}
				onChange={(e:any) => setLastname(e.target.value)}
				placeholder="Enter your last name"
			/>
		</Form>
	)
}

const FormEmail = ({ email, onCancel, onSave, saving }: { email:string, onCancel:Function, onSave:Function, saving:boolean }) => {
	const [_email, setEmail] = useState(email)

	return (
		<Form 
			title="Edit email" 
			onCancel={onCancel} 
			onSave={() => onSave({ email:_email })} 
			saving={saving}>
			<TextField
				label="Email"
				defaultValue={email}
				onChange={(e:any) => setEmail(e.target.value)}
				placeholder="Enter your email"
			/>
		</Form>
	)
}

const FormPhone = ({ phone, onCancel, onSave, onError }: { phone:any, onCancel:Function, onSave:Function, onError:Function }) => {
	const [updatePhone, phoneOp] = useMutation(UPDATE_PHONE)
	const [_code, setCode] = useState(phone?.code)
	const [_number, setNumber] = useState(phone?.number)
	const [_codeError, setCodeError] = useState(false)
	const [_numberError, setNumberError] = useState(false)

	useEffect(() => {
		if (!phoneOp.loading && phoneOp.called) {
			if (!phoneOp.error)
				onSave({ code:_code, number:_number })
			else {
				console.error(phoneOp.error)
				onError()
			}
		}
	}, [phoneOp, _code, _number, onSave, onError])

	const _onSave = () => {
		if (!_code) {
			setCodeError(true)
			onError(`Country code is required`)
			return 
		} else
			setCodeError(false)
		if (!_number) {
			setNumberError(true)
			onError(`Phone number is required`)
			return 
		} else
			setNumberError(false)

		updatePhone({
			variables: {
				code: _code||null,
				number: _number||null
			}
		})
	}

	const onKeyPress = (e:any) => {
		const keyCode = e.keyCode || e.which
		const isNumber = keyCode > 47 && keyCode < 58
		if (!isNumber) {
			e.preventDefault()
			return false
		}
	}

	const onPaste = (e:any) => { // only keep numbers
		const clipboardData = e.clipboardData || { getData:(e:any) => e }
		e.target.value = (clipboardData.getData('Text')||'').replace(/[^0-9]/g,'')
		e.preventDefault()
	}

	return (
		<Form 
			title="Edit phone number" 
			onCancel={onCancel} 
			onSave={_onSave} 
			saving={phoneOp.loading}>
			<TextField
				label="Country code"
				required
				defaultValue={phone?.code}
				onChange={(e:any) => setCode(e.target.value)}
				onKeyPress={onKeyPress}
				onPaste={onPaste}
				placeholder="Enter your country code"
				error={_codeError}
			/>
			<TextField
				label="Phone number"
				required
				defaultValue={phone?.number}
				onChange={(e:any) => setNumber(e.target.value)}
				onKeyPress={onKeyPress}
				onPaste={onPaste}
				placeholder="Enter your phone number"
				error={_numberError}
			/>
		</Form>
	)
}

const FormCompany = ({ metadata, onCancel, onSave, onError }: { metadata:any, onCancel:Function, onSave:Function, onError:Function }) => {
	const [updateMeta, metaOp] = useMutation(UPDATE_METADATA)
	const [_company, setCompany] = useState(metadata.company)
	const [_abn, setAbn] = useState(metadata.abn)

	useEffect(() => {
		if (!metaOp.loading && metaOp.called) {
			if (!metaOp.error)
				onSave({ ...metadata, company:_company, abn:_abn })
			else {
				console.error(metaOp.error)
				onError()
			}
		}
	}, [metaOp, _company, _abn, metadata, onSave, onError])

	const _onSave = () => {
		updateMeta({
			variables: {
				metadata: JSON.stringify({ ...metadata, company:_company, abn:_abn })
			}
		})
	}

	const onKeyPress = (e:any) => {
		const keyCode = e.keyCode || e.which
		const isNumber = keyCode > 47 && keyCode < 58
		if (!isNumber) {
			e.preventDefault()
			return false
		}
	}

	const onPaste = (e:any) => { // only keep numbers
		const clipboardData = e.clipboardData || { getData:(e:any) => e }
		e.target.value = (clipboardData.getData('Text')||'').replace(/[^0-9]/g,'')
		e.preventDefault()
	}

	return (
		<Form 
			title="Edit company details" 
			onCancel={onCancel} 
			onSave={_onSave} 
			saving={metaOp.loading}>
			<TextField
				label="Company"
				defaultValue={metadata.company||''}
				onChange={(e:any) => setCompany(e.target.value)}
				placeholder="Enter your company name"
			/>
			<TextField
				label="ABN"
				defaultValue={metadata.abn||''}
				onChange={(e:any) => setAbn(e.target.value)}
				onKeyPress={onKeyPress}
				onPaste={onPaste}
				placeholder="Enter ABN"
			/>
		</Form>
	)
}

const FormGrowerNbr = ({ metadata, onCancel, onSave, onError }: { metadata:any, onCancel:Function, onSave:Function, onError:Function }) => {
	const [updateMeta, metaOp] = useMutation(UPDATE_METADATA)
	const [_growerNbr, setGrowerNbr] = useState(metadata.growerNbr||'')

	useEffect(() => {
		if (!metaOp.loading && metaOp.called) {
			if (!metaOp.error)
				onSave({ ...metadata, growerNbr:_growerNbr })
			else {
				console.error(metaOp.error)
				onError()
			}
		}
	}, [metaOp, _growerNbr, metadata, onSave, onError])

	const _onSave = () => {
		updateMeta({
			variables: {
				metadata: JSON.stringify({ ...metadata, growerNbr:_growerNbr })
			}
		})
	}

	return (
		<Form 
			title="Edit Grower's number" 
			onCancel={onCancel} 
			onSave={_onSave} 
			saving={metaOp.loading}>
			<TextField
				label="Grower number"
				defaultValue={metadata.growerNbr||''}
				onChange={(e:any) => setGrowerNbr(e.target.value)}
				placeholder="Enter your Grower number"
			/>
		</Form>
	)
}

const Profile = () => {
	const head = <Helmet>
		<title>Wand - Profile</title>
	</Helmet>
	
	const userOp = useQuery(GET_USER_GQL)

	const history = useHistory()
	const location = useLocation()
	const modal = useRef<HTMLIonModalElement>(null)
	const [mode, setMode] = useState('')
	const [fullname, setFullname] = useState('')
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState({})
	const [metadata, setMetadata] = useState<any>({})
	const ionToat = useIonToast()
	const toast = useMemo(() => new Toast(...ionToat, { duration:4000 }),[ionToat])

	useEffect(() => {
		if (!userOp.loading) {
			const u = (userOp.data?.users?.data||[])[0]||{}
			setMetadata(parseMeta(u) || {})
			setFirstname(u.first_name||'')
			setLastname(u.last_name||'')
			setEmail(u.email||'')
			setPhone(u.phone||{})
		}
	}, [userOp])

	useEffect(() => { // Makes sure that the toast is closed when we leave this page
		return () => {
			toast.dismiss()
		}
	},[location, toast])

	useEffect(() => {
		// Listens to screen changes to adjust the sheet modal. Used to adpat the sheet modal when the keyboard is on.
		const onKeyboardShow = (e:any) => {
			const m:any = modal?.current || {}
			if (m.getCurrentBreakpoint)
				m.getCurrentBreakpoint().then((bp:any) => {
					if (bp > 0) { // modal open
						const expectedBp = getExpectedModalBreakpoint(e.target.innerHeight || 0)
						if (expectedBp != bp)
							m.setCurrentBreakpoint(expectedBp)
					}
				})		
		}
		window.addEventListener('resize', onKeyboardShow, false)

		return () => {
			window.removeEventListener('resize', onKeyboardShow)
		}
	},[])

	useEffect(() => {
		setFullname([firstname, lastname].filter(x => x).join(' '))
	}, [firstname, lastname])

	useEffect(() => {
		if (mode) {
			const bp = getExpectedModalBreakpoint(window.innerHeight||0)
			const m:any = modal.current || {}
			m.present()
			if (bp && bp != 0.6)
				setTimeout(() => {
					m.setCurrentBreakpoint(bp)
				}, 400)
		}
	}, [mode, toast])

	const hideModal = () => {
		setMode('')
		modal.current?.dismiss()
	}

	const onFullnameSave = (value:any) => {
		setFirstname(value.firstname||'')
		setLastname(value.lastname||'')
		hideModal()
		toast.show('Full name successfully saved')
	}

	const onEmailSave = (value:any) => {
		console.log(value)
		// setSaving(true)
		// const [,user] = await getUser()
		// user.email = value.email||''
		// const [errors] = await updateUser(user)
		// setSaving(false)
		// if (errors) {
		// 	toast.show('Failed to update email', { error:true, closeText:'close' })
		// } else {
		// 	setEmail(user.email||'')
		// 	hideModal()
		// 	toast.show('Email successfully saved')
		// }
	}

	const onPhoneSave = async (value:any) => {
		setPhone(value||{})
		hideModal()
		toast.show('Phone number successfully saved')
	}

	const onCompanySave = async (value:any) => {
		setMetadata(value||{})
		hideModal()
		toast.show('Company details successfully saved')
	}

	const onGrowerNbrSave = async (value:any) => {
		setMetadata(value||{})
		hideModal()
		toast.show('Grower number successfully saved')
	}

	const onDidDismiss = () => {
		if (mode)
			setMode('')
	}

	const onError = (fieldName:string) => (msg?:string) => {
		toast.show(msg || `Failed to save ${fieldName}`, { error:true, closeText:'close' })
	}

	// Manages redirection to login page if user is not authenticated
	const [redirect, setRedirect] = useState<boolean | null>(null)
	useEffect(() => getSessionUser().then((resp: any) => setRedirect(resp && resp[0] ? true : false)), [])
	if (redirect === null)
		return null

	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<div>
			<IonPage>
				{head}
				<TitleHeader title="Profile" onBack={() => history.push(SETTINGS_PATH)} />
				<IonContent fullscreen>
					<Box sx={{ paddingTop: '20px' }}>
						<Field fields={[{label:"Email", value:email}]} onEdit={() => setMode('email')} icon={<EmailIcon/>} disabled={true}/>
						<Divider/>
						<Field fields={[{label:"Full name", value:fullname}]} onEdit={() => setMode('fullname')} icon={<PersonIcon/>}/>
						<Divider/>
						<Field fields={[{label:"Phone", value:concatPhoneDetails(phone)}]} onEdit={() => setMode('phone')} icon={<LocalPhoneIcon/>}/>
						<Divider/>
						<Field fields={[{label:"Company", value:metadata?.company},{label:"ABN", value:metadata?.abn}]} onEdit={() => setMode('company')} icon={<BusinessIcon/>}/>
						<Divider/>
						<Field fields={[{label:"Grower number", value:metadata?.growerNbr}]} onEdit={() => setMode('growerNbr')} icon={<NumbersIcon/>}/>
					</Box>
				</IonContent>
			</IonPage>
			<IonModal 
				ref={modal} 
				breakpoints={[0, 0.6, 0.8, 0.9]} 
				initialBreakpoint={0.6} 
				onDidDismiss={onDidDismiss}
				className="profile" 
				handle={false}>{
				mode == 'email' 
				? <FormEmail email={email} saving={false} onCancel={hideModal} onSave={onEmailSave}/> :
				mode == 'fullname' 
				? <FormFullname firstname={firstname} lastname={lastname} onCancel={hideModal} onSave={onFullnameSave} onError={onError('full name')}/> :
				mode == 'phone' 
				? <FormPhone phone={phone} onCancel={hideModal} onSave={onPhoneSave} onError={onError('phone number')}/> :
				mode == 'company' 
				? <FormCompany metadata={metadata} onCancel={hideModal} onSave={onCompanySave} onError={onError('company details')}/> :
				mode == 'growerNbr' 
				? <FormGrowerNbr metadata={metadata} onCancel={hideModal} onSave={onGrowerNbrSave} onError={onError('grower\'s number')}/> : ''
			}
			</IonModal>
		</div>
	)
}

export default Profile