import { useLocation } from 'react-router-dom'
import { useAuth } from '../auth'

const ACCEPT_KEY = 'goanna-wand-accept-id'

const AcceptInvitation = () => {
	const location = useLocation<any>()
	const searchParams = new URLSearchParams(location.search)
	const invite_id = searchParams.get('accept')
	const newuser = searchParams.get('newuser')

	if (invite_id)
		localStorage.setItem(ACCEPT_KEY, invite_id)
	
	useAuth({
		redirect_to_signup: newuser === 'true', 
		path_when_logged_in: '/'
	})
	
	return null
}

export default AcceptInvitation