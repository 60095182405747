import { useLocation } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'

const UPGRADE_KEY = 'goanna-wand-upgrade-mode'

const MasterDiv = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 80px;
`

const ConfirmUpgrade = () => {
	const location = useLocation<any>()
	const searchParams = new URLSearchParams(location.search)
	const plan = searchParams.get('plan')

	if (plan) {
		localStorage.setItem(UPGRADE_KEY, plan)
	}

	setTimeout(() => {
		window.location.href = '/settings'
	}, 4000)
	
	return (<MasterDiv>
		<CircularProgress/>
	</MasterDiv>)
}

export default ConfirmUpgrade