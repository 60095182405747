import { gql, useQuery, useMutation } from '@apollo/client'


const GET_MEMBERS_GQL = () => gql`
query get_members {
	members {
		id
		user_id
		first_name
		last_name
		email
		create_date
		roles
		status
	}
}`

const GET_TEAMS_GQL = () => gql`
query get_teams {
	teams {
		id
		name
		invite_date
	}
}`

const ACCEPT_INVITE_GQL = () => gql`
mutation member_accept_invite($id:ID!) {
	member_accept_invite(id:$id) {
		message
	}
}`

const DECLINE_INVITE_GQL = () => gql`
mutation member_decline_invite($id:ID!) {
	member_decline_invite(id:$id) {
		message
	}
}`

export const useTeams = options => {
	const opts = {}
	if (options?.onCompleted)
		opts.onCompleted = data => options.onCompleted(data?.teams||[])
	if (options?.onError)
		opts.onError = options.onError
	const query = useQuery(GET_TEAMS_GQL(), opts)

	return query
}

export const useAcceptInvite = options => {
	const { onCompleted, onError } = options || {}

	const queryOption = {}
	if (onCompleted)
		queryOption.onCompleted = onCompleted
	if (onError)
		queryOption.onError = onError

	const [accept] = useMutation(ACCEPT_INVITE_GQL())

	return id => accept({
		variables: { 
			id
		},
		...queryOption
	})
}

export const useDeclineInvite = options => {
	const { onCompleted, onError } = options || {}

	const queryOption = {}
	if (onCompleted)
		queryOption.onCompleted = onCompleted
	if (onError)
		queryOption.onError = onError

	const [decline] = useMutation(DECLINE_INVITE_GQL())

	return id => decline({
		variables: { 
			id
		},
		...queryOption
	})
}

export const useMembers = options => {
	const { onCompleted, onError } = options || {}

	const queryOption = {}
	if (onCompleted)
		queryOption.onCompleted = onCompleted
	if (onError)
		queryOption.onError = onError

	const query = useQuery(GET_MEMBERS_GQL(), queryOption)

	return query
}





