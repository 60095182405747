import { useState, useMemo, Fragment } from 'react'
import { useTheme } from '@mui/material/styles'
import { gql, useMutation } from '@apollo/client'
import { validateEmail } from 'puffy-core/validate'
import { IonContent, IonPage, useIonToast } from '@ionic/react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import styled from 'styled-components'
import ProgressBar from './ProgressBar'
import Toast from '../utils/Toast'
import { ActionHeader } from './Header'
import { useUser, UPDATE_FULLNAME } from '../data/user'

// padding:20px 26px 40px 26px;
const MasterDiv = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px;

	& > p,
	& > div,
	& > label {
		margin-bottom: 15px;
	}

	& .upgrade-now {
		cursor: pointer;
		text-decoration: underline;
		text-transform: uppercase;
		font-weight: 600;
		color: var(--mu-primary-dark);
	}

	& .invite-input > div {
		height: 184px;
	}

	& .it-toolbar {
		display: grid;
		grid-template-rows: 60px;
		grid-template-columns: 100px 1fr 100px;
	}

	& .upgrade {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 6px;
		margin-right:6px;

		& p {
			display: flex;
			justify-content: center;
		}

		& .upgrade-btn {
			cursor: pointer;
			height: 28px;
			width: 96px;
			font-size: 14px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--mu-primary-dark);
			background: -webkit-linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
			background: linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
			color: white;
			border-radius: 30px;
			font-weight: 500;

			& .upgrade-text {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: -5px;
			}

			& svg {
				font-size:20px;
				margin-right: 3px;
			}
		}

		&.muted {
			& .upgrade-btn {
				background: #e2ebf3;
				color:var(--mu-primary-dark);
				& svg {
					font-size:16px;
				}
			}
		}

		&.get-pro {
			padding-top: 0px;
			& .upgrade-btn {
				width: 132px;
			}
		}
	}
`

const PlanButton = ({ ...props }) => {
	return (<div className={"upgrade" + (props.muted ? ' muted' : '')}>
		<div className="upgrade-btn" onClick={props.upgrade}>
			<div className="upgrade-text">
				{props.icon}
				{props.label}
			</div>
		</div>
	</div>)
}

const INVITE_MEMBER = gql`
mutation invite_member($email:String!, $sender_name:String!, $first_name:String, $last_name:String, $roles:[MemberRoleEnum]!, $type:MemberTypeEnum!) {
	member_invite(
		email:$email
		sender_name:$sender_name
		first_name:$first_name
		last_name:$last_name
		roles:$roles,
		type:$type) {
			id
			user_id
			first_name
			last_name
			email
			create_date
			roles
	}
}`

// const _void = (s:any) => s
const get_sender_name = (user:any) => {
	const { first_name, last_name } = user || {}
	if (first_name && last_name)
		return { first_name, last_name }
	else 
		return { first_name:'', last_name:'' }
}

const FormInviteMember = ({ ...props }:any) => {
	const theme = useTheme()
	const css_vars:any = {
		'--mu-primary-light': theme.palette.primary.light,
		'--mu-primary-dark': theme.palette.primary.dark,
		'--mu-primary-main': theme.palette.primary.main
	}
	const user_op = useUser()
	const [invite_member, invite_member_op] = useMutation(INVITE_MEMBER)
	const [updateFullname, fullnameOp] = useMutation(UPDATE_FULLNAME)
	
	let loading = user_op.loading || invite_member_op.loading || fullnameOp.loading
	const { first_name:_sender_firstname, last_name:_sender_lastname } = get_sender_name(user_op?.data)

	const [email, set_email] = useState('')
	// const [first_name, set_firstname] = useState('')
	// const [last_name, set_lastname] = useState('')
	const [sender_firstname, set_sender_firstname] = useState(_sender_firstname)
	const [sender_lastname, set_sender_lastname] = useState(_sender_lastname)
	// const [role, set_role] = useState('')
	const [error_sender_firstname, set_error_sender_firstname] = useState(false)
	const [error_sender_lastname, set_error_sender_lastname] = useState(false)
	// const [error_role, set_error_role] = useState(false)
	const [error_email, set_error_email] = useState(false)
	const [invite_type, set_invite_type] = useState('JOIN_ORG')
	const [upgrade_msg, set_upgrade_msg] = useState(false)
	const ionToast = useIonToast()
	const toast = useMemo(() => new Toast(...ionToast, { duration:4000 }), [ionToast])

	const on_cancel = props.onCancel || (() => null)
	const on_confirm = () => {
		const _email = `${email||''}`.trim()
		if (!_email) {
			toast.show('The Email field is required', { error:true, closeText:'close' })
			set_error_email(true)
		} else if (!validateEmail(_email)) {
			toast.show(`"${_email}" is not a valid email`, { error:true, closeText:'close' })
			set_error_email(true)
		} else if (!sender_firstname) {
			toast.show('Your first name is required', { error:true, closeText:'close' })
			set_error_sender_firstname(true)
		} else if (!sender_lastname) {
			toast.show('Your last name is required', { error:true, closeText:'close' })
			set_error_sender_lastname(true)
		} else {
			set_error_email(false)
			set_error_sender_firstname(false)
			set_error_sender_lastname(false)

			let exec_fn = next => next()
			if (!_sender_firstname || !_sender_lastname)
				exec_fn = next => {
					updateFullname({
						variables: {
							first_name: sender_firstname,
							last_name: sender_lastname
						},
						onCompleted(data:any) {
							console.log(`INFO - User's full name updated`)
							console.log(data)
							next()
						},
						onError(error:any) {
							console.error(error)
							toast.show('Oops, an error happened on our side. Failed update your first and last name. Please try again. If the issue persists, please contact us.', { error:true, closeText:'close' })
							set_error_sender_firstname(true)
							set_error_sender_lastname(true)
						}
					})
				}

			exec_fn(() => {
				const variables = {
					email: _email,
					sender_name: `${sender_firstname} ${sender_lastname}`,
					first_name:null,
					last_name:null,
					roles:['read_and_spray'],
					type:invite_type
				}
				invite_member({
					variables,
					onCompleted(data:any) {
						if (props.onConfirm)
							props.onConfirm()
					},
					onError(error:any) {
						console.error(error)
						const err_msg = (error?.graphQLErrors||[])[0]?.message||''
						const user_not_in_wand = err_msg.indexOf('wait until that user joins WAND') >= 0
						const quota_exceeded = err_msg.indexOf('quota exceeded') >= 0
						if (user_not_in_wand)
							set_invite_type('JOIN_WAND')
						else if (quota_exceeded)
							set_upgrade_msg(err_msg)
						else if (props.on_error)
							props.on_error()
					}
				})
			})
		}
	}

	const upgrade_to_paid = user_op?.data?.plan?.name == 'free'

	const upgrade = () => {
		if (props.on_upgrade)
			props.on_upgrade()
	}

	const invite_to_wand = invite_type != 'JOIN_ORG'
	const invite_action = invite_to_wand ? 'Invite to WAND' : 'Invite to team'

	return (
	<IonPage {...props}>
		{loading && <ProgressBar/>}{!user_op?.data?.plan || upgrade_to_paid ? 
		<ActionHeader actionName="Upgrade" onCancel={on_cancel} onConfirm={upgrade}/> :
		<ActionHeader actionName={invite_action} onCancel={on_cancel} onConfirm={on_confirm}/>}
		<IonContent className="ion-padding">
			<MasterDiv>{!user_op?.data?.plan ? null : upgrade_to_paid ? 
				<div className="upgrade" style={css_vars}>
					<Typography sx={{ width:'330px', marginBottom:'10px' }}>
						Unlock this feature with a paid account.
					</Typography>
					<PlanButton  
						upgrade={upgrade}
						icon={<UpgradeIcon/>}
						label="Upgrade"/>
				</div> :
				<Fragment>{_sender_firstname && _sender_lastname ? null :
					<Fragment>
						<Typography sx={{ width:'330px', marginBottom:'10px' }}>From:</Typography>
						<TextField 
							type="text"
							required
							name="sender_firstname"
							label="Your first name" 
							disabled={loading} 
							error={error_sender_firstname}
							onChange={e => set_sender_firstname(`${e?.target?.value}`)}/>
						<TextField 
							type="text"
							required
							name="sender_lastname"
							label="Your last name" 
							disabled={loading} 
							error={error_sender_lastname}
							onChange={e => set_sender_lastname(`${e?.target?.value}`)}/>
					</Fragment>}
					<Typography sx={{ width:'330px', marginBottom:'10px' }}>To:</Typography>
					<TextField 
						type="email"
						label="Member's email" 
						required
						disabled={loading} 
						error={error_email}
						onChange={e => set_email(e.target.value)}/>{!invite_to_wand ? null :
					<Typography sx={{ marginBottom:'10px', color:'red', fontStyle:'italic' }}>
						{email} is not a WAND user yet. Click on the <b>"INVITE TO WAND"</b> button to invite that person to WAND. Once they have joined WAND, try inviting them to your team again.
					</Typography>}{!upgrade_msg ? null :
					<Typography sx={{ marginBottom:'16px', color:'red', fontStyle:'italic' }}>
						{upgrade_msg} To increase that quota, <span className="upgrade-now" style={css_vars} onClick={upgrade}>Upgrade now!</span>
					</Typography>}
					{/*<FormControl fullWidth>
						<InputLabel id="role">Role *</InputLabel>
						<Select
							id="role"
							required
							label="Role"
							error={error_role}
							onChange={e => set_role(`${e?.target?.value}`)}
							>
							<MenuItem value={'read'}>Read</MenuItem>
							<MenuItem value={'read_and_spray'}>Read and Spray</MenuItem>
						</Select>
					</FormControl>*/}
{/*					<TextField 
						type="text"
						name="firstname"
						label="First name" 
						disabled={loading} 
						onChange={e => set_firstname(`${e?.target?.value}`)}/>
					<TextField 
						type="text"
						name="lastname"
						label="Last name" 
						disabled={loading} 
						onChange={e => set_lastname(`${e?.target?.value}`)}/>*/}
				</Fragment>}
			</MasterDiv>
		</IonContent>
	</IonPage>)
}

export default FormInviteMember