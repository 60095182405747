import { useState, useEffect, useMemo } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { useMutation, useQuery, gql } from '@apollo/client'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useIonToast } from '@ionic/react'
import Toast from '../utils/Toast'
import DatePickerField from './DatePickerField'
import ProgressBar from './ProgressBar'
import { ActionHeader } from './Header'
import { useLogFeatureInfo, useLogFeatureError } from '../utils/session'
import './FormSpray.css'

const FOUR_HOURS_MS = 4*60*60*1000
const CREATE_SPRAY_LOG = gql`
mutation spray_log_create($tower_id: ID!, $reading_stream_id: ID!, $start_date: String!, $finish_date: String!, $note: String, $organization_ids:[ID]) {
	spray_log_create(
		tower_id: $tower_id
		reading_stream_id: $reading_stream_id
		start_date: $start_date
		finish_date: $finish_date
		note: $note
		organization_ids: $organization_ids
	) {
		message
	}
}`

const GET_SPRAY_TEAM_PERMISSIONS = gql`
query spray_team_permissions($tower_id: ID!) {
	spray_team_permissions(where:{ tower_id:$tower_id }) {
		organization_id
		granter_id
		granter_email
		granter_first_name
		granter_last_name
		granter_company
	}
}`

const FormSpray = ({ towerId, towerName, readingId, onCancel, onConfirm }:{ towerId:number, towerName:string, readingId:number, onCancel:Function, onConfirm:Function }) => {
	const theme = useTheme()
	const [createSprayLog, { error, loading:_loading, called }] = useMutation(CREATE_SPRAY_LOG)
	const permissions_op = useQuery(GET_SPRAY_TEAM_PERMISSIONS, { variables: { tower_id:towerId } })
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date(Date.now()+FOUR_HOURS_MS))
	const [startDateError, setStartDateError] = useState(false)
	const [endDateError, setEndDateError] = useState(false)
	const [note, setNote] = useState<string | null>(null)
	const [organization_ids, set_organization_ids] = useState<string[]>([])
	const ionToast = useIonToast()
	const toast = useMemo(() => new Toast(...ionToast, { duration:4000 }), [ionToast])
	const logFeat = useLogFeatureInfo()
	const logFeatError = useLogFeatureError()

	const loading = permissions_op.loading || _loading
	const permissions = (permissions_op.data?.spray_team_permissions || []).map((x:any) => {
		const name = x.granter_company || [x.granter_first_name, x.granter_last_name].filter((y:any) => y).join(' ') || x.granter_email
		return {
			id: x.organization_id,
			name
		}
	})

	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	}

	function getStyles(name: string, organization_ids: string[], theme: Theme) {
		return {
			fontWeight:
				organization_ids.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
			}
	}

	useEffect(() => {
		return () => {
			toast.dismiss()
		}
	})

	useEffect(() => {
		if (!loading && called) {
			if (error) {
				console.error(error)
				toast.show('Failed to create spray log. Please contact support for further help.', { error:true, closeText:'close' })
			} else {
				toast.show('Spray log successfully registered')
				onConfirm()
			}
		} 
	}, [loading, called, toast, onConfirm, error])

	const onSave = async () => {
		if (startDate > endDate) {
			setStartDateError(true)
			setEndDateError(true)
			toast.show('The finish date must be strictly greater than the start date', { error:true, closeText:'close' })
			return
		}
		
		const variables = {
			tower_id: towerId,
			reading_stream_id: readingId,
			start_date: startDate.toISOString(),
			finish_date: endDate.toISOString(),
			note,
			organization_ids
		}

		createSprayLog({ 
			variables,
			onCompleted() {
				logFeat({ title:'spray', content:towerName })
			},
			onError(error) {
				logFeatError({ title:'spray', content:JSON.stringify({ towerId, towerName, error:error.message }) })
			}
		})
	}

	const onStartDateChange = (val:any) => {
		if (startDateError)
			setStartDateError(false)
		setStartDate(val)
		if (val > endDate)
			setEndDate(val)	
	}
	const onEndDateChange = (val:any) => {
		if (endDateError)
			setEndDateError(false)
		setEndDate(val)
		if (val < startDate)
			setStartDate(val)
	}

	const select_teams = (event:any) => {
		const { target: { value } } = event
		set_organization_ids(value)
	}

	useEffect(() => {
		if ((permissions||[]).length == 1)
			set_organization_ids([permissions[0].id])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return (<IonPage>
		{loading && <ProgressBar/>}
		<ActionHeader actionName="Save" onCancel={onCancel} onConfirm={onSave}/>
		<IonContent className="ion-padding">
			<Typography className="spray-instruction">
				Enter a start and finish time, then press the "Save" button to record your spray event:
			</Typography>
			<Box className="spray-input-form">{!(permissions||[]).length ? null :
				<div className="select-org">
					<FormControl sx={{ m: 0, width: '100%' }}>
						<InputLabel id="spray-on-behalf">Spray on behalf of your team</InputLabel>
						<Select
							labelId="spray-on-behalf"
							id="demo-multiple-name"
							multiple
							value={organization_ids}
							onChange={select_teams}
							input={<OutlinedInput label="Spray on behalf of your team" />}
							MenuProps={MenuProps}
							>{permissions.map((permission:any) => (
								<MenuItem
								key={permission.id}
								value={permission.id}
								style={getStyles(permission.id, organization_ids, theme)}
								>
								{permission.name}
								</MenuItem>))}
						</Select>
					</FormControl>
				</div>}
				<DatePickerField 
					label="Start date" 
					labelTime="Start time" 
					required 
					date={startDate} 
					time={true}
					onChange={onStartDateChange} 
					error={startDateError} 
					disabled={loading}/>
				<DatePickerField 
					label="Finish date" 
					labelTime="Finish time" 
					required 
					date={endDate} 
					minDate={startDate}
					time={true}
					onChange={onEndDateChange} 
					error={endDateError} 
					disabled={loading}/>
				<TextField
					className="spray-textarea"
					label="Optional note"
					onChange={(e:any) => setNote(e.target.value)} 
					multiline
					rows={6}
					disabled={loading}
					placeholder="Add an optional note"
				/>
			</Box>
		</IonContent>
	</IonPage>)
}

export default FormSpray