import { useState } from 'react'
import { IonContent, IonPage, useIonToast } from '@ionic/react'
import { Redirect, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import { LOGIN_PATH, SETTINGS_PATH } from '../../../path'
import { TitleWithActionHeader } from '../../../components/Header'
import ProgressBar from '../../../components/ProgressBar'
import Toast from '../../../utils/Toast'
import { useTeams } from '../../../data/team'
import { useAuth } from '../../../auth'

const MasterDiv = styled.div`
	padding:0px 0px 40px 0px;


	& .nomembers {
		padding: 26px;
		display:flex;
		justify-content:center;
	}

	& .list {
		display:flex;
		flex-direction: column;
		& .member {
			display:grid;
			grid-template-columns: 2fr 5fr 3fr;
			height: 45px;
			align-items: center;
			border-bottom: 1px solid #e6e6e6;

			&.items {
				&:hover {
					background-color:#f8f8f8;
				}

				& .item {
					cursor:pointer;
				}
			}

			& .item {
				white-space:nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-left:20px;
			}

			&.header {
				background-color:#dfdfdf;
				& .item {
					font-weight:bold;
					text-transform:uppercase;
				}
			}

			@media (max-width: 768px) {
				grid-template-columns: 1fr 2fr;
				& .role {
					display: none;
				}

				& .item {
					font-size:14px;
				}
			}
		}
	}
`

const format_date = (date:String) => {
	if (date)
		return date.split('T')[0]
	else 
		return '--'
}

const Teams = () => {
	const head = <Helmet>
		<title>Wand - Joined Teams</title>
	</Helmet>

	const authed = useAuth()
	const history = useHistory()
	const [teams, set_teams] = useState([])
	const teams_op = useTeams({
		onCompleted:(data:any) => {
			set_teams(data||[])
		},
		onError:(error:any) => {
			console.error(error)
			toast.show('Failed to load the teams you are part of. Please contact support.', { error:true, closeText:'close' })
		}
	})

	const toast = new Toast(...useIonToast(), { duration:5000 })

	return authed !== null && !authed ? <Redirect to={LOGIN_PATH} /> : (
		<div>
			<IonPage>{teams_op.loading && 
				<ProgressBar/>}
				{head}
				<TitleWithActionHeader 
					title="Joined Teams" 
					actionName=""
					onBack={() => history.push(SETTINGS_PATH)} />
				<IonContent fullscreen>
					<MasterDiv>
						<div className="list">
							<div className="member header">
								<div className="item email">Team</div>
								<div className="item name">Invite Date</div>
							</div>{!teams.length ? 
							<div className="nomembers">
								<Typography variant="caption" component="p" className="text" color="text.secondary" >
									You are not part of any teams yet.
								</Typography>
							</div> : teams.map((team:any, key:any) => (
							<div className="member items" key={team.id} onClick={() => null}>
								<div className="item email">{team.name||'--'}</div>
								<div className="item name">{format_date(team.invite_date)}</div>
							</div>))}
						</div>
					</MasterDiv>
				</IonContent>
			</IonPage>
		</div>
	)
}

export default Teams

