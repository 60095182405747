import { useEffect, useState, Fragment } from 'react'
import { IonContent, IonPage, useIonToast } from '@ionic/react'
import Toast from '../utils/Toast'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { formatDate } from 'puffy-core/date'
import { useQuery, gql } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { useTheme } from '@mui/material/styles'
import ProgressBar from '../components/ProgressBar'
import { TitleHeader } from '../components/Header'
import { getSessionUser } from '../auth'
import { LOGIN_PATH, NOTIFICATION_PATH } from '../path'
import { useAcceptInvite, useDeclineInvite } from '../data/team'

const DATE_FORMAT = { format:'dd/MM/yyyy HH:mm' }

const GET_NOTIFICATION = gql`
query get_notifications($id:ID!) {
	notifications(
		where: {
			id: $id
		}
	) {
		data {
			create_date
			data {
				id
				title
				sub_title
				type
				message
				body
				link_url
			}
		}
	}
}`

const Container = styled.div`
	padding: 26px;

	& p {
		line-height: 1.3;
	}

	& .cta {
		display: flex;
	}

	& .button {
		position: relative;
		background-color: var(--btn-color,black);
		color: var(--btn-txt-color,white);
		border-radius: 6px;
		cursor: pointer;
		border: 2px solid var(--btn-border-color, transparent);
		width: 90px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& .gap {
		width:10px;
	}
`

const NotificationMessage = ({ ...props }) => {
	const location = useLocation<any>()
	const [accepting, set_accepting] = useState(false)
	const [declining, set_declining] = useState(false)
	const id = ((location.pathname||'').match(/\/notifications\/message\/([0-9]*?)$/)||[])[1]
	const notificationOp = useQuery(GET_NOTIFICATION, {
		variables: {
			id: id||0
		}
	})
	const notification = (notificationOp.data?.notifications?.data||[])[0]
	const theme = useTheme()
	const css_vars:any = {
		'--mu-primary-light': theme.palette.primary.light,
		'--mu-primary-dark': theme.palette.primary.dark,
		'--mu-primary-main': theme.palette.primary.main,
		'--mu-warning': theme.palette.error.light
	}
	const toast = new Toast(...useIonToast(), { duration:5000 })
	const accept = useAcceptInvite({
		onCompleted() {
			set_accepting(false)
			toast.show('Invitation accepted')
		},
		onError(error:any) {
			set_accepting(false)
			const err_msg = `${error?.message}`
			console.error(error)
			if (err_msg.indexOf('already accepted') >= 0)
				toast.show('Invitation already accepted')
			else if (err_msg.indexOf('not found') >= 0 || err_msg.indexOf('cannot be accepted anymore') >= 0)
				toast.show('This invitation has expired or has been deactivated.', { error:true, closeText:'close' })
			else
				toast.show('Oops, an error happened on our end. Please try again later.', { error:true, closeText:'close' })
		}
	})
	const decline = useDeclineInvite({
		onCompleted() {
			set_declining(false)
			toast.show('Invitation declined')
		},
		onError(error:any) {
			set_declining(false)
			const err_msg = `${error?.message}`
			console.error(error)
			if (err_msg.indexOf('already declined') >= 0)
				toast.show('Invitation already declined')
			else if (err_msg.indexOf('already accepted') >= 0)
				toast.show('Invitation already accepted. Cannot decline an accepted invitation.')
			else if (err_msg.indexOf('not found') >= 0 || err_msg.indexOf('cannot be declined anymore') >= 0)
				toast.show('This invitation has expired or has been deactivated.', { error:true, closeText:'close' })
			else
				toast.show('Oops, an error happened on our end. Please try again later.', { error:true, closeText:'close' })
		}
	})

	const head = <Helmet>
		<title>Wand - {notification?.data?.title||'Message'}</title>
	</Helmet>

	const history = useHistory()
	const html_str = notification?.data?.body || notification?.data?.message || ''
	const html_message = parse(html_str)

	const [,invite_id, invitee] = html_str.match(/^invite_id_([0-9]+?)_invitee_(.+?)$/)||[]

	// Manages redirection to login page if user is not authenticated
	const [redirect, setRedirect] = useState<boolean | null>(null)
	useEffect(() => getSessionUser().then((resp: any) => setRedirect(resp && resp[0] ? true : false)), [])
	if (redirect === null)
		return null

	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<IonPage>
			{notificationOp.loading && <ProgressBar/>}
			{head}
			<TitleHeader 
				title={<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 'calc(100vw - 90px)' }}>{notification?.data?.title}</div>} 
				subTitle={notification?.create_date ? formatDate(new Date(notification?.create_date), DATE_FORMAT) : ''} 
				onBack={() => history.push(NOTIFICATION_PATH)} />
			<IonContent fullscreen>
				{!notificationOp.loading && <Container style={css_vars}>{invite_id && invitee ?
					<Fragment>
						<p>Hi there,</p>

						<p>{invitee} has just invited you as a valued member of their WAND team.</p>

						<p>Click on the link below to accept this invitation.</p>

						<p>Feel free to reach out if you need assistance or have any questions. Welcome aboard!</p>

						<p>Warm regards,</p>

						<p>The WAND Team</p>

						<div className="cta">
							<div 
								className="button"
								onClick={() => {
									set_declining(true)
									decline(invite_id)
								}} 
								style={{ '--btn-color':'transparent', '--btn-txt-color':'var(--mu-warning)', '--btn-border-color':'var(--mu-warning)' }}>{!declining ?
								'Decline' :
								<CircularProgress
									size={24}
									sx={{
										color: 'var(--mu-warning)',
										position: 'absolute',
										top: '15px',
										left: '50%',
										marginTop: '-12px',
										marginLeft: '-12px',
									}}/>}
							</div>
							<div className="gap"></div>
							<div 
								className="button" 
								onClick={() => {
									set_accepting(true)
									accept(invite_id)
								}}
								style={{ '--btn-color':'var(--mu-primary-main)' }}>{!accepting ?
								'Accept' :
								<CircularProgress
									size={24}
									sx={{
										color: 'white',
										position: 'absolute',
										top: '15px',
										left: '50%',
										marginTop: '-12px',
										marginLeft: '-12px',
									}}/>}
							</div>
						</div>
					</Fragment> :
					html_message}
				</Container>}
			</IonContent>
		</IonPage>
	)
}

export default NotificationMessage