import { IonContent, IonPage } from '@ionic/react'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import { ActionHeader } from './Header'

// padding:20px 26px 40px 26px;
const MasterDiv = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 20px;
`
const ConfirmAcceptInvite = ({ ...props }:any) => {
	const theme = useTheme()
	
	return (
	<IonPage {...props}>
		<ActionHeader actionName="" cancelName="Close" onCancel={props.on_cancel}/>
		<IonContent className="ion-padding">
			<MasterDiv>{!props.error ?
				<Typography sx={{ fontSize:'16px' }}>
					Invitation accepted 🥳 
				</Typography> : 
				<Typography sx={{ fontSize:'16px', color:theme.palette.error.light }}>{props.error}</Typography>}
			</MasterDiv>
		</IonContent>
	</IonPage>)
}

export default ConfirmAcceptInvite