import { useEffect, useState } from 'react'
import { catchErrors, wrapErrorsFn } from 'puffy-core/error'
import { Amplify, Auth as _Auth } from 'aws-amplify'
import { LOGIN_PATH, SIGNUP_PATH } from './path'

if (!process.env.REACT_APP_AWS_USER_POOLS_ID)
	throw new Error(`Missing required environment variable 'REACT_APP_AWS_USER_POOLS_ID'`)
if (!process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID)
	throw new Error(`Missing required environment variable 'REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID'`)
if (!process.env.REACT_APP_AWS_USER_POOLS_DOMAIN)
	throw new Error(`Missing required environment variable 'REACT_APP_AWS_USER_POOLS_DOMAIN'`)

Amplify.configure({
	aws_project_region: 'ap-southeast-2',
	aws_cognito_region: 'ap-southeast-2',
	aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
	aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
	oauth: {
		domain: process.env.REACT_APP_AWS_USER_POOLS_DOMAIN
	},
	aws_cognito_username_attributes: ['EMAIL'],
	aws_cognito_social_providers: [],
	aws_cognito_signup_attributes: [],
	aws_cognito_mfa_configuration: 'OFF',
	aws_cognito_mfa_types: [],
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength: 6,
		passwordPolicyCharacters: [
			'REQUIRES_LOWERCASE',
			'REQUIRES_UPPERCASE',
			'REQUIRES_NUMBERS',
			'REQUIRES_SYMBOLS'
		]
	},
	aws_cognito_verification_mechanisms: ['EMAIL']
})

export const Auth = _Auth

/**
 * Gets the current user OAuth 2 tokens for the logged in user. 
 * Fails if the user is not logged in. Error message: 'No current user'
 * 
 * @return	{Object}	tokens	
 * @return	{Object}		.accessToken
 * @return	{String}			.jwtToken	JWT Token value
 * @return	{Object}			.payload	Deserialized JWT token into its object
 * @return	{Object}		.idToken
 * @return	{String}			.jwtToken	JWT Token value
 * @return	{Object}			.payload	Deserialized JWT token into its object
 * @return	{Object}		.refreshToken	
 * @return	{String}			.token		Token value (not JWT)
 */
export const getSessionTokens = () => catchErrors((async() => {
	const e = wrapErrorsFn(`Failed to read current session tokens`)

	const [errors, res] = await catchErrors(Auth.currentSession())
	if (errors)
		throw e(errors)

	const accessToken = res.getAccessToken()
	const idToken = res.getIdToken()
	return {
		accessToken,
		idToken,
		refreshToken: {
			token: res.refreshToken
		}
	}
})())

/**
 * Gets the OAuth access token
 * @param	{Void}
 *
 * @return	{String}	accessToken
 */
export const getAccessToken = () => catchErrors((async() => {
	const e = wrapErrorsFn(`Failed to get OAuth access token`)

	const [errors, tokens] = await getSessionTokens()
	if (errors)
		throw e(errors)
	return tokens.accessToken.jwtToken
})())

/**
 * Gets the current user session for the logged in user. 
 * Fails if the user is not logged in. Error message: 'The user is not authenticated'
 * 
 * @return	{Object}	user	
 * @return	{String}		.username					Cognito user ID (e.g., '1234-4567-8910-1111')
 * @return	{Object}		.pool
 * @return	{Object}		.Session
 * @return	{Object}		.client
 * @return	{Object}		.signInUserSession
 * @return	{Object}			.accessToken
 * @return	{String}				.jwtToken			JWT Token value
 * @return	{Object}				.payload			Deserialized JWT token into its object
 * @return	{Object}			.idToken
 * @return	{String}				.jwtToken			JWT Token value
 * @return	{Object}				.payload			Deserialized JWT token into its object
 * @return	{Object}			.refreshToken	
 * @return	{String}				.token				Token value (not JWT)
 * @return	{Object}		.authenticationFlowType
 * @return	{Object}		.storage
 * @return	{Object}		.keyPrefix
 * @return	{Object}		.userDataKey
 * @return	{Object}		.attributes
 * @return	{String}			.sub
 * @return	{Boolean}			.email_verified
 * @return	{String}			.email
 * @return	{String}		.preferredMFA				e.g., 'NOMFA' or 'MFA'
 */
export const getSessionUser = () => catchErrors((async() => {
	const e = wrapErrorsFn(`Failed to read current user session`)

	const [errors, user] = await catchErrors(Auth.currentAuthenticatedUser())
	if (errors)
		throw e(errors)

	return user
})())


export const logOut = () => catchErrors((async() => {
	const e = wrapErrorsFn(`Failed to log out`)

	const [errors] = await catchErrors(Auth.signOut())
	if (errors)
		throw e(errors)
})())


export const useAuth = (options?:any) => {
	const { redirect_to_signup, path_when_logged_in } = options || {}
	const [authed, setAuth] = useState<boolean | null>(null)
	useEffect(() => {
		getSessionUser().then((resp: any) => {
			const notLoggedIn = resp && resp[0] ? true : false
			setAuth(!notLoggedIn)
			if (notLoggedIn)
				window.location.href = redirect_to_signup ? SIGNUP_PATH : LOGIN_PATH
			else if (path_when_logged_in)
				window.location.href = path_when_logged_in
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	return authed
}









